<template>
  <div style="background: #fff">
    <div class="search">
      <van-field
        class="search-input"
        v-model="keyword"
        placeholder="请输入达人名称"
        @update:model-value="inputChange"
        @keyup.enter="search"
      />
      <van-button
        class="search-button"
        type="primary"
        @click="search"
      >
        搜索
      </van-button>
    </div>
    <van-pull-refresh
      v-model="refreshing"
      :head-height="50"
      @refresh="onRefresh"
    >
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div
          v-for="item in data"
          :key="item.id"
        >
          <cell-star-info
            :data="item"
            @click="goToStarDetail(item)"
          />
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import CellStarInfo from '/src/components/CellStarInfo';
import { mapActions } from 'vuex';

export default {
  name: 'List',
  components: {
    CellStarInfo
  },
  data() {
    return {
      keyword: '',
      refreshing: false,
      loading: false,
      finished: false,
      data: []
    };
  },
  methods: {
    inputChange(val) {
      // this.disabledSearch = !(val && val.length > 0);
      // console.log(val);
    },
    search() {
      this.page = 1;
      this.loading = true;
      this.refreshing = true;
      this.finished = false;
      this.onLoad();

    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.page = 1;
      this.onLoad();
    },
    async onLoad() {
      const res = await this.queryTiktokStar({ nickname: this.keyword, page: this.page });
      if (!res) {
        this.loading = false;
        this.refreshing = false;
        return;
      }

      if (this.refreshing) {
        this.data = [];
        this.refreshing = false;
      }

      res.data.forEach((x) => {
        this.data.push(x);
      });
      this.loading = false;
      this.page = res.page.current_page + 1;
      if (res.page.current_page >= res.page.last_page) {
        this.finished = true;
      }
    },
    goToStarDetail(data) {
      this.$router.push({
        name: 'StarDetail',
        params: {
          id: data.id,
        },
      });
    },
    ...mapActions({
      queryTiktokStar:
        'star/queryTiktokStar',
    }),
  },
};
</script>

<style lang="less" scoped>
.search {
  position: relative;
  padding: 40px 30px;
  border-bottom: 1px solid #E0E0E0;

  .search-input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    background: #F7F7F7;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 65px;
    border: 1px solid #E6E6E6;
    //font-size: 30px;
  }

  .search-button {
    position: absolute;
    top: 40px;
    right: 30px;
    width: 140px;
    height: 80px;
    border-radius: 65px;
    //font-size: 30px;
  }
}

</style>